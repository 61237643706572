import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"

import axios from 'axios'

import ScrollAnimation from 'react-animate-on-scroll';


class NousJoindre extends React.Component {

  constructor(props){
    super(props)
    this.state = {
        name: "",
        email: "",
        phone: "",
        message: "",
        nameError: false,
        emailEmail:false,
        phoneError:false,
        messageError:false,
        formReady:false,
        formSent:false,
        formSendError:false,
        formLabel: 'Veuillez remplir les champs'
    }
  }

  validateField(event,type){
      if(type === 'name'){
          this.setState({name:event.target.value})
          if(event.target.value.length < 3 && event.target.value.length > 0){
              this.setState({nameError: true}, () => {
                  this.checkForm()
              })
          } else {
              this.setState({nameError: false}, () => {
                  this.checkForm()
              })
          }
      } else if(type === 'email') {
          this.setState({email:event.target.value})
          if(event.target.value.length > 1 && !this.validateEmail(event.target.value)){
              this.setState({emailError: true}, () => {
                  this.checkForm()
              })
          } else {
              this.setState({emailError: false}, () => {
                  this.checkForm()
              })
          }
      } else if(type === 'phone') {
          this.setState({phone:event.target.value})
          if(event.target.value.length > 1 && !this.validatePhone(event.target.value)){
              this.setState({phoneError: true}, () => {
                  this.checkForm()
              })
          } else {
              this.setState({phoneError: false}, () => {
                  this.checkForm()
              })
          }
      } else if(type === 'message'){
          this.setState({message:event.target.value})
          if(event.target.value.length < 10 && event.target.value.length > 0){
              this.setState({messageError: true}, () => {
                  this.checkForm()
              })
          } else {
              this.setState({messageError: false}, () => {
                  this.checkForm()
              })
          }
      }
  }

  validatePhone(phone){
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    return re.test(phone)
  }



  validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
  }

  checkForm(){
      if(this.checkFormReady()){
          this.setState({formReady: true,formLabel:"Envoyer"})
      } else {
          this.setState({formReady: false})
      }
  }

  checkFormReady(){

      if(this.state.emailError){
          return false
      }
      if(this.state.nameError){
          return false
      }
      if(this.state.phoneError){
          return false
      }
      if(this.state.messageError){
          return false
      }
      if(this.state.email.length < 1){
          return false
      }
      if(this.state.name.length < 1){
          return false
      }
      if(this.state.phone.length < 1){
          return false
      }
      if(this.state.message.length < 10){
          return false
      }
      return true
  }

  sendMessage(){
    this.setState({formLabel:'Un instant...'})
    axios.post('https://api.slapform.com/nbertrand@gestionmieux-etre.com',{
      name:this.state.name,
      email:this.state.email,
      phone:this.state.phone,
      message:this.state.message
    }).then((data) => {
        this.setState({
          formSent: true,
          formLabel:'Envoyé avec succès!'
        })
    }).catch((error) => {
        this.setState({
          formSent: false,
          formSendError: true,
          formLabel:'Erreur, veuillez réessayer'
        })
    })
  }

  render(){
    return(
      <Layout>
        <SEO title="Nous joindre | Gestion Mieux-Être" />
        <div className="flex-container pad-page-med">
            <div className="half center-content tleft">
                <h2>Passez à l’action !</h2>
                <p>Nous avons à cœur de soutenir le développement des gens que nous accompagnons. Nous sommes déterminés à contribuer concrètement à votre succès. </p>
                <p>
                <span>Téléphone:</span> <a href="tel:15145833111">514 583 3111</a><br />
                <span>Courriel:</span> <a href="mailto:info@gestionmieux-etre.com">info@gestionmieux-etre.com</a><br /><br />

                Il nous fera plaisir de vous contacter très prochainement.
                </p>

            </div>
            <div className="half">
                { this.state.formSent ?
                    <h6 style={{'marginLeft': '0px'}}>Nous avons bien reçu votre demande.<br />Nous vous contacterons dans les plus brefs délais</h6>
                    :
                    ''
                }
                <div id="contact-form" class={this.state.formSent ? 'form-disabled' : ''}>
                    <input type="hidden" name="form54" value="5132463134543" />
                    <h5>Formulaire de contact</h5>
                    <small>Veuillez simplement remplir le formulaire ci-dessous<br /><br /></small>
                    <label for="nom">Votre nom</label><span className={this.state.nameError ? "errors show" : "errors"}>Veuillez entrer votre nom</span>
                    <input onChange={(event) => { this.validateField(event,'name')}} value={this.state.name}  type="text" name="name" />
                    <label for="courriel">Courriel</label><span className={this.state.emailError ? "errors show" : "errors"}>Veuillez entrer un courriel valide</span>
                    <input onChange={(event) => { this.validateField(event,'email')}} value={this.state.email} type="text" name="email" />
                    <label for="telephone">Téléphone</label><span className={this.state.phoneError ? "errors show" : "errors"}>Veuillez entrer votre téléphone</span>
                    <input onChange={(event) => { this.validateField(event,'phone')}} value={this.state.phone} type="text" name="phone" />
                    <label for="message">Message</label><span className={this.state.messageError ? "errors show" : "errors"}>Veuillez entrer un message de quelques mots</span>
                    <textarea onChange={(event) => { this.validateField(event,'message')}} name="message">{this.state.message}</textarea>
                    <button onClick={() => { this.sendMessage() }} className={this.state.formReady ? "button-link" : "button-link disabled"} type="button">{this.state.formLabel}</button>
                </div>
            </div>
        </div>
      </Layout>
    )
  }

}

export default NousJoindre
